<template>
    <div class="container">

        <div class="input-group my-1 has-feedback">
            <span class="m-1" >结算方式 <i class="fa-solid fa-asterisk rqd">选择相应的模式</i></span>
            <select class="form-control w-100 rounded form-select" v-model="form.settlement_type">
                <option v-for="(value,code) in SETTLEMENT_TYPE" :key="code" :value="code"> {{ value }} </option>
            </select>
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">名 称<i class="fa-solid fa-asterisk rqd"></i></label>
            <input type="text" class="form-control w-100 rounded"  placeholder="请输入名称" required v-model.trim.lazy="form.name" />
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">到期日期(选填：如是阶段性的价格可设多条) </label>
            <input type="date" class="form-control w-100 rounded" v-model.trim.lazy="form.end_day" />
        </div>
        <!-- 价格和工时开始 -->
        <div v-if="form.settlement_type !==2">
            <div class="input-group my-1 has-feedback">
                <label class="m-1">白班工时单位(分钟) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="请输入白班工时如:10个小时输入600" required v-model.trim.lazy="form.day_time" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">夜班工时单位(分钟) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="请输入夜班工时" required v-model.trim.lazy="form.night_time" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">白班单价单位(元) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="请输入小时工单价白班" required v-model.trim.lazy="form.day_price" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">夜班单价单位(元) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="请输入小时工单价夜班" required v-model.trim.lazy="form.night_price" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">预发单价单位(元)  选填</label>
                <input type="text" class="form-control w-100 rounded"  placeholder="如有预发请输入" v-model.trim.lazy="form.pretest_price" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">预发工时单位(分钟) 选填</label>
                <input type="text" class="form-control w-100 rounded"  placeholder="如有预发请输入" v-model.trim.lazy="form.pretest_time" />
            </div>
        </div>
        <!-- 返费 -->
        <div v-if="form.settlement_type == 2">
            <div class="input-group my-1 has-feedback">
                <label class="m-1">返费单位(元) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="如有返费请输入" required v-model.trim.lazy="form.return_fee" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">返费周期单位(天) <i class="fa-solid fa-asterisk rqd"></i></label>
                <input type="text" class="form-control w-100 rounded"  placeholder="如有返费请输入" required v-model.trim.lazy="form.return_day" />
            </div>
        </div>
        <!-- end -->

        <!-- 条件开始 -->

        <div class="input-group my-1 has-feedback" v-if="form.settlement_type !== 2">
            <label class="m-1">结算周期</label>
            <div class="row w-100">
                <select class="form-control col" v-model="form.settlement_cycle[0]">
                    <option value="">开始日期</option>
                    <option v-for="code in day" :key="code" :value="code" >{{ code }}</option>
                </select>&nbsp;&nbsp;
                <select class="form-control col" v-model="form.settlement_cycle[1]">
                    <option value="">结束日期</option>
                    <option v-for="code in day" :key="code" :value="code" >{{ code }}</option>
                </select>
            </div>
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">几天有薪({{ des }})</label>
            <input type="text" class="form-control w-100 rounded"  placeholder="请输入天数" required v-model.trim.lazy="form.day_salary" />
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">补差日期</label>
            <input type="text" class="form-control w-100 rounded"  placeholder="请输入补差日期间" required v-model.trim.lazy="form.reissue_day" />
            <p class="m-0 fs-5">备注: <i>补发预发工时未发完的工资、返费的支付日期，可以大于31天——则按多少天后支付补差</i></p>
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">发薪日期</label>
            <input type="text" class="form-control w-100 rounded"  placeholder="请输入发薪时间" required v-model.trim.lazy="form.payday" />
        </div>
        <div class="input-group my-1 has-feedback">
            <label class="m-1">模式说明</label>
            <textarea class="form-control w-100 rounded" rows="5" v-model="form.description"></textarea>
        </div>
        <!-- end -->
        <button class="btn btn-success w-100 mt-3">提交</button>
    </div>
</template>

<script>
import { reactive, ref, watchEffect, onMounted } from 'vue'
import { SETTLEMENT_TYPE } from '@/lib/data'
import { getUrlCode } from '@/lib/unitls'
export default {
    name: 'staffSettlementEdit',
    setup() {
        let day = ref(31)
        let des = ref()
        let form = reactive({
            id: 1,
            name:"",
            jobs_id: null,
            settlement_type: null,
            end_day: null,
            day_time: 600,
            night_time: 600,
            day_price: 18.5,
            night_price: 19,// j日结夜班单价
            settlement_cycle:[1,31],
            pretest_price: 18,// 预发单价
            pretest_time: 600,// 预发工时
            return_fee: 500, //返费：同工同酬时，上满几个月给的一次性
            return_day: 60, //返费周期
            day_salary:7,
            reissue_day:25,// 补差日期
            payday: 20,  //发薪日期
            description:""//模式说明
        })

        onMounted(() => {
           form.id = getUrlCode('id')
        })

        watchEffect(() => {
            if(form.settlement_type == 1) {
                day.value = 7
                des.value = "即几天内走了无薪"
                return
            }
            if(form.settlement_type == 0) {
                des.value = "即几天内走了无薪、几天后一天一发"
                return
            }
            if(form.settlement_type ==3) {
                des.value = "即几天内走了无薪"
                return
            }
        })

        return {
            des,
            form,
            SETTLEMENT_TYPE,
            day
        }
    }

}
</script>

<style>

</style>